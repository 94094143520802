/**
 * Created by mac on 5/6/17.
 */

cleverapps.styles.COLORS = cleverapps.overrideColors(cleverapps.styles.COLORS, {
    PATH_COLOR: new cc.Color(255, 223, 4, 255),
    PATH_HARD_COLOR: new cc.Color(255, 223, 4, 255),
    LETTER: new cc.Color(64, 44, 137, 255),
    SELECTED_LETTER: new cc.Color(235, 149, 1, 255),
    COLOR_VICTORY: new cc.Color(240, 73, 0, 255),
    COLOR_LOADING: new cc.Color(50, 50, 255, 255),
    PLAYER_ALARM_COLOR: new cc.Color(252, 148, 37, 255)
});

cleverapps.styles.DECORATORS = cleverapps.overrideStyles(cleverapps.styles.DECORATORS, {
    REWARDS_TEXT_STROKE: {
        color: new cc.Color(111, 55, 14, 255),
        size: 2
    }
});

cleverapps.styles.FONTS = cleverapps.overrideFonts(cleverapps.styles.FONTS, {

    CURRENT_TEXT: {
        size: 60,
        color: cleverapps.styles.COLORS.LETTER
    },

    LETTERS_TEXT: {
        size: 80,
        color: cleverapps.styles.COLORS.WHITE
    },

    BOARD_LETTERS_TEXT: {
        size: 60,
        color: cleverapps.styles.COLORS.LETTER
    },

    SELECTED_LETTERS_TEXT: {
        size: 80,
        color: cleverapps.styles.COLORS.SELECTED_LETTER
    },

    CLOVERS_TEXT: {
        size: 50,
        name: "nostroke",
        color: cleverapps.styles.COLORS.COLOR_BROWN
    },

    REWARDS_TEXT: {
        size: 40,
        color: cleverapps.styles.COLORS.COLOR_BROWN
    }
});

cleverapps.overrideStyles(cleverapps.styles.BoosterAmountView, {
    background: {
        scale: 1
    },
    amount: {
        scale: 0.7
    }
});

cleverapps.overrideStyles(cleverapps.styles.BoardView, {
    BgTop: {
        x: { align: "center" },
        y: { align: "top", dy: 10 }
    },

    Background: {
        padding: {
            bottom: 40
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.GameScene, {
    sound: {
        delay: 1.7
    }
});

cleverapps.overrideStyles(cleverapps.styles.CloversView, {
    width: 200,
    height: 70,

    text: {
        y: { align: "center" },
        x: { align: "center", dx: 40 }
    },

    icon: {
        width: 120,

        x: { align: "left", dx: 5 },
        y: { align: "center", dy: 10 }
    },

    position: [
        {
            x: { align: "left", dx: 10 },
            y: { align: "top", dy: -690 }
        },
        {
            x: { align: "left", dx: 10 },
            y: { align: "top", dy: -740 }
        },
        {
            x: { align: "left", dx: 10 },
            y: { align: "top", dy: -740 }
        }
    ]
});

cleverapps.overrideStyles(cleverapps.styles.CurrentView, {
    bg: {
        height: 70,
        padding: {
            x: 50
        },
        resize: true,
        y: { align: "center" }
    },

    regularText: {
        position: {
            x: 0, y: 0
        }
    },

    position: [
        { x: { align: "center" }, y: { align: "bottom", dy: 460 } },
        { x: { align: "center" }, y: { align: "bottom", dy: 500 } },
        { x: { align: "center" }, y: { align: "bottom", dy: 550 } }
    ],
    statusIcon: {
        offsetX: 45
    },
    content: {
        y: -10
    },
    extraAnimation: {
        scale: 0.5
    }
});

cleverapps.overrideStyles(cleverapps.styles.CurrentLetterView, {
    noBg: true
});

cleverapps.overrideStyles(cleverapps.styles.CharView, {
    width: 50,
    height: 50
});

cleverapps.overrideStyles(cleverapps.styles.KeypadView, {
    radius: {
        default: 190,
        "3": 160,
        "4": 170,
        "5": 185,
        "6": 190
    },

    controls: {
        width: 110,
        height: 110
    },

    bg: {
        diameter: 140
    }
});

cleverapps.overrideStyles(cleverapps.styles.KeypadLetterView, {
    noBg: true
});

cleverapps.overrideStyles(cleverapps.styles.LoaderSceneBackground, {
    logoAnimation: {
        pos: {
            factorX: 0.5,
            factorY: 0.7
        },

        posVert: {
            factorX: 0.5,
            factorY: 0.8
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.PackWindow, {
    Title: {
        fitTo: {
            width: 350,
            height: 70
        },
        dx: 20,
        dy: 265,
        delay: 0.7
    },

    discountLabel: {
        dx: 265,
        dy: 310,
        delay: 1,
        duration: 0.675,
        fitTo: {
            width: 150
        }
    },

    animation: {
        "default": {
            idleDelay: -1.3,
            dx: 0,
            dy: 0
        },

        "starterPack0": {
            idleAnimation: "idle_800",
            introAnimation: "open_800"
        },

        "starterPack": {
            idleAnimation: "idle_2600",
            introAnimation: "open_2600"
        },

        "starterPack2": {
            idleAnimation: "idle_17000",
            introAnimation: "open_17000"
        }
    }
});

cleverapps.overrideStyles(cleverapps.styles.LoadingAnimation, {
    text: false
});

cleverapps.overrideStyles(cleverapps.styles.ConfirmExitWindow, {
    animation: {
        width: 400,
        height: 400,

        x: { align: "center" },
        y: { align: "center", dy: -70 }
    }
});

cleverapps.overrideStyles(cleverapps.styles.LevelView, {
    clickSize: {
        width: 100,
        height: 80
    },

    LevelId: {
        y: 40
    },

    dy: 0
});

cleverapps.overrideStyles(cleverapps.styles.WeeklyCupView, {
    margin: -6,

    padding: {
        top: 100,
        bottom: 50
    },

    helpButton: {
        x: { align: "right", dx: -5 },
        y: { align: "top", dy: -10 }
    },

    title: {
        text: {
            width: 520,
            x: { align: "center", dx: 1 }
        }
    },

    table: [{ height: 800 }, { height: 460 }, { height: 460 }]
});