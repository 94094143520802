/**
 * Created by vladislav on 10.02.2020
 */

var ShortMetaScene = MainScene.extend({
    onSceneLoaded: function () {
        this._super();

        this.addShortProgress();
        this.addPlayButton();

        if (cleverapps.isKnockoutGame()) {
            this.addTable();
        }
    },

    onTransitionAnimationDidStart: function () {
        this._super();

        this.progressView.hide();
    },

    addPlayButton: function () {
        var playButton = new PlayButtonView();
        this.addChild(playButton);

        cleverapps.focusManager.registerControl("play_button", new HidingNode(playButton, HidingNode.DIR.NONE));
    },

    addTable: function () {
        this.table = new WeeklyCupView();
        this.addChild(this.table);
        this.table.setPositionRound(cleverapps.styles.ShortMetaScene.table);
        this.table.setLocalZOrder(-1);
    },

    addShortProgress: function () {
        this.progressView = new ShortMetaProgressView();
        this.progressView.setPositionRound(cleverapps.styles.ShortMetaProgressView.position);
        this.addChild(this.progressView);
    },

    getBackgroundStyles: function () {
        var bundleName = "backgrounds_main";
        var imageName = "background_image";

        if (cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL && bundles[bundleName].urls.background_image_vertical) {
            imageName = "background_image_vertical";
        }

        return {
            bundle: bundleName,
            backgroundId: imageName,
            animation: bundles.backgrounds_main.jsons.background_animation
        };
    },

    setupChildren: function () {
        if (!this.isAllLoaded) {
            return;
        }

        this._super();
        this.progressView.setPositionRound(cleverapps.styles.ShortMetaProgressView.position);

        if (this.table) {
            this.table.setPositionRound(cleverapps.styles.ShortMetaScene.table);
        }
    },

    listBundles: function () {
        return ["main", "dialogues"];
    }
});

cleverapps.styles.ShortMetaScene = {
    table: {
        x: { align: "center", dx: 0 },
        y: { align: "center", dy: 190 }
    }
};