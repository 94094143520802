/**
 * Created by vladislav on 13.02.2020
 */
var ShortMetaProgressView = cc.Node.extend({
    ctor: function () {
        this._super();
        this.setAnchorPoint(0.5, 0.5);

        this.createLevels();

        this.updateSize();
        this.setupChildren();
    },

    onEnter: function () {
        this._super();
        this.updateSize();
        this.setupChildren();
    },

    updateSize: function () {
        var sceneSize = cleverapps.resolution.getSceneSize();
        var scale = 2 - Math.min(sceneSize.width / sceneSize.height, 1);

        this.levelsView.children.forEach(function (levelView) {
            levelView.baseScaleX = levelView.baseScaleY = scale;
            levelView.setScale(scale);
        });
        cleverapps.UI.wrap(this.levelsView);
        this.setContentSize2(this.levelsView.getContentSize());
        this.parent && this.updateScale();
    },

    setupChildren: function () {
        var styles = cleverapps.styles.ShortMetaProgressView;
        this.levelsView.setPositionRound(this.width / 2, this.height / 2);
        this.levelsView.children.forEach(function (levelView, index) {
            var x = index * (levelView.width + styles.margin.x);
            var marginY = styles.margin.y;
            if (cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL) {
                marginY = styles.margin.mobileY;
            }
            var y = (index % 2 ? -1 : 1) * marginY;

            levelView.setPositionRound(x, y);
        });
    },

    hide: function () {
        this.setCascadeOpacityEnabledRecursively(true);
        this.runAction(new cc.FadeOut(0.15));
    },

    updateScale: function () {
        var styles = cleverapps.styles.ShortMetaProgressView;

        var sceneWidth = cleverapps.resolution.getSceneSize().width;

        var scale = 1 / this.parent.scale;
        var width = this.width + styles.paddingX;
        if (width > sceneWidth) {
            scale = scale * sceneWidth / width;
        }
        this.setScale(scale);
    },

    createLevels: function () {
        var location = cleverapps.meta.getSelectedLocation();
        var levels = [];

        for (var progress = 0; progress < ShortMetaLocation.LEVELS_PER_LOCATION; progress++) {
            levels.push(new ShortMetaLevel(location, progress));
        }

        var levelsView = this.levelsView = new cc.Node();
        levelsView.setAnchorPoint(0.5, 0.5);
        levels.forEach(function (level) {
            var view = new LevelView(level);
            levelsView.addChild(view);
        });
        this.addChild(levelsView);
    }
});

cleverapps.styles.ShortMetaProgressView = {
    paddingX: 200,

    margin: {
        x: 10,
        y: 0,
        mobileY: 0
    },

    position: {
        x: { align: "center", dx: 0 },
        y: { align: "bottom", dy: 400 }
    }
};