/**
 * Created by mac on 9/18/22
 */

var ShortMetaLocation = function (locationId) {
    BaseLocation.call(this, locationId);
};

ShortMetaLocation.prototype = Object.create(BaseLocation.prototype);
ShortMetaLocation.prototype.constructor = ShortMetaLocation;

ShortMetaLocation.prototype.load = function (stored) {
    this.progress = stored.progress || 0;
};

ShortMetaLocation.prototype.getInfo = function () {
    return {
        progress: this.progress
    };
};

ShortMetaLocation.prototype.changeProgress = function (progress) {
    this.setProgress(this.progress + progress);
};

ShortMetaLocation.prototype.setProgress = function (progress) {
    this.progress = progress;
    this.save();
};

ShortMetaLocation.prototype.getProgress = function () {
    return this.progress;
};

ShortMetaLocation.prototype.isCompleted = function () {
    return this.progress >= ShortMetaLocation.LEVELS_PER_LOCATION;
};

ShortMetaLocation.prototype.gamePlayed = function (outcome) {
    var victory = cleverapps.knockoutGame ? cleverapps.knockoutGame.rumble.outcome === Rumble.OUTCOME_WIN : outcome === GameBase.OUTCOME_VICTORY;
    if (victory) {
        this.multiplyRewards(this.getProgress());
        this.changeProgress(1);
    }
};

ShortMetaLocation.prototype.getReward = function (level) {
    return RewardsConfig.ShortMeta[level % ShortMetaLocation.LEVELS_PER_LOCATION];
};

ShortMetaLocation.prototype.multiplyRewards = function (level) {
    var reward = this.getReward(level);
    if (reward) {
        for (var type in reward) {
            var amount = Game.currentGame.rewards[type] * (reward[type].factor || 1);
            amount -= Game.currentGame.rewards[type];

            if (type === GameBase.REWARD_HARD) {
                Game.currentGame.addHardReward(amount);
            } else if (type === GameBase.REWARD_SOFT) {
                Game.currentGame.addSoftReward(amount);
            }
        }
    }
};

ShortMetaLocation.GetMainScene = function () {
    return ShortMetaScene;
};

ShortMetaLocation.LEVELS_PER_LOCATION = 10;
